import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {closeToggles, getDcdOptions} from "@/assets/js/ui.init";
import {
    applyBtnEnable,
    cancelSupport,
    checkUploadBinaries,
    getFileUploadFailResults,
    grdTots,
    grdTots2,
    proofDivCdDcds,
    saveSupport,
    schlDivCdDcds,
    schlInDivCdDcds,
    setCostProof,
    showComplete,
    uploadCostproof,
    extensions, addDate
} from "@/assets/js/modules/support/support-common";
import {
    emptyNumberOnClick,
    initParams,
    isSuccess,
    nullEmpty,
    numberComma,
    numbersOnly,
    paramCheck, replaceDateToYmd,
    setParams, ymdStrToFormat
    , getItem, lengthCheck, removeComma, dateToDateFormat,
} from "@/assets/js/util";
import {
    ACT_DELETE_CANCEL_LRN_SPRT_SCHL,
    ACT_INSERT_LRN_SPRT_SCHL,
    ACT_UPDATE_LEARN_SUPPORT_SCHOOL,
    MUT_HIDE_HEADER,
    ACT_GET_BEF_TERM_BURS_AMT,
    ACT_GET_LEARN_SUPPORT_SCHOOL_SPRT_CREDIT,
    ACT_GET_SCHL_CREDIT_APPLY_AVL_CHK
} from "@/store/modules/support/support";
import {useStore} from "vuex";

export const supportTuitionVupSetup = (props, {emit}) => {
    const store = useStore();

    const {showMessage, showLoading, hideLoading} = useAlert();
    const isLoading = ref(false);

    const isReadOnly = computed(() => props.data && props.data.aplySttCdDcd !== '2106001');

    const isUseSubText  = ref(false);
    // const isMobile = computed(() => store.state.auth.isMobile);

    const showPrice = ref(true);        // 납부금액 - Expand Collapse
    const showReducedPrice = ref(true); // 감면금액 - Expand Collapse
    const userAgreement = ref(false);   // 동의

    const schlDivCdDcdOpt = computed(() => getDcdOptions('2061'));
    const schlInDivCdDcdOpt = computed(() => getDcdOptions('2062'));
    const termDivCdDcdOpt = computed(() => getDcdOptions('2063').filter(x=>x.alterCd == "202309"));

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);


    const appYear = computed(() => {
        const yearItems = [];
        let yyyymm = dateToDateFormat(new Date, 'yyyy.MM');
        let yyyy = yyyymm.split('.')[0];
        let mm = yyyymm.split('.')[1];
        yearItems.push({val:yyyy});

        if(parseInt(mm) < 7 ){
            yearItems.push({val:(parseInt(yyyy)-1).toString()});
        }

        return yearItems;

    });

    const majorModal = ref(false);
    const collegeModal = ref(false);

    const isAcademicCreditBank = ref(false);

    const param = reactive({
        costTuitFeeSn: 0,
        schlDivCdDcd: '', //  학교구분
        schlInDivCdDcd: '', // 재학구분
        termDivCdDcd: '', // 학기구분
        // tuitFeePayDd: '', // 등록금 납부 일
        termEndDd: '', //학기 종료 일자
        sprtYear:dateToDateFormat(new Date, 'yyyy'),  //지원대상 년도
        sprtEntrFee:0,  // 입학금
        grdAvg: 0.0, // 평점평균
        grdTot: 0.0, // 평점 총점
        rtnYn: 'N',
        rtnAmt: 0,
        addAmtYn: 'N',
        addAmt: 0,
        // 고지금액 합계는 납부금액 더해서 보여주는 값
        // 감면금액 합계
        sprtExptAmt: 0, // 지원대상 금액
        befTermBursAmt: 0,// 직전학기학비지원후장학금수혜금액
        bursAmtBnfitRegno: 0, // 직전학기 장학금수혜 관리번호
        sprtAmt: 0,// 지원금액
        // 등록금 납입증명서
        inetProofNo: '',// 인터넷증명서발급번호
        // 장학금 수혜 여부
        bursAmtBnfitYn: 'N',
        dpstYmd: '', // 입금 일자
        dpstBankNm: '', // 입금 은행명
        dpstActno: '', // 입금계좌번호
        bursAmtAmt: 0, // 장학금 금액
        regCrseCrdit: 0, // 수강학점
        degAcqsSchedPerid: '', // 학위취득예정시기
        majorNm2: '', // 학과
    });

    const befSchlDivCdDcd = ref(''); // 직전학기 학교구분

    const major = ref({
        majorCd: '', // 전공
        majorNm: ''
    });

    const school = ref({
        scholCd: '',
        scholNm: ''
    });

    const fees = reactive({
        // 납부금액:지원
        sprtEntrFee: 0, //입학금
        sprtTuitFee: 0, // 수업료/등록금
        // sprtSchlOperCost: 0, // 학교운영지원비(육성/기성회비)
        // sprtTotStdntFee: 0, // 총학생회비(자치회비)
        // sprtPraceCost: 0, // 실험실습비등
        // // 납부금액: 지원제외
        // nsprtStdntFee: 0, // 학생회비
        // nsprtBookCost: 0, // 교재대
        // nsprtGradPrepAmt: 0, // 졸업준비(앨범)금
    });

    const reduce = reactive({
        // 감면
        redGradeBursAmt: 0, // 성적
        redNtnBursAmt: 0, // 국가지원
        redEtcNm: '', // 감면기타명
        redEtcBursAmt: 0, // 기타
        // nsprtEtcAmt: 0, // 기타
        etcBursAmtNm: '', //기타장학금명
        etcBursAmt: 0, //기타장학금
    });

    const appBefTermBursAmt = ref(0); // 적용 직전학기 장학급수혜금액
    // PROOF_DIV_CD_SCHL_SCHOLAR_CERT
    const totalPayment = ref(0), totalReduce = ref(0);

    const transcriptFiles = ref({ files: [], removed: [], binaries: [] }),
        tuitPayCertFiles = ref({ files: [], removed: [], binaries: [] }),
        schlScholarCertFiles = ref({ files: [], removed: [], binaries: [] });

    const toggles = reactive({
        schlDivCdDcds: false,
        schlInDivCdDcds: false,
        termDivCdDcds: false,
        grdTots: false,
        termEndDd:false,
        // tuitFeePayDd: false,
        dpstYmd: false,
        sprtYears:false
    });

    const toggleGrdTot = () => {
        toggles.grdTots=!toggles.grdTots
        closeToggles(toggles,'grdTots');
    };

    const selectGrdTot = (idx) => {
        param.grdTot = grdTots[idx].val;
        closeToggles(toggles);
    };

    const selectGrdTot2 = (idx) => {
        param.grdTot = grdTots2[idx].val;
        closeToggles(toggles);
    };

    const usrAgrmntRef = ref(null);

    const initLocalParams = () => {
        initParams(param);
        initParams(major.value);
        initParams(school.value);
        initParams(fees);
        initParams(reduce);
        initParams(transcriptFiles.value);
        initParams(tuitPayCertFiles.value);
        initParams(schlScholarCertFiles.value);
        userAgreement.value=false;
        if(props.data == null){
            usrAgrmntRef.value.allUnChk();
        }
        closeToggles(toggles);
        //closeModal();
        isLoading.value = false;
    };

    const closeModal = () => {
        if (props.reList instanceof Function)
            props.reList();

        initLocalParams();

        // if(isMobile.value) {
        //     store.commit(`support/${MUT_SHOW_MAIN}`);
        // }else {
            emit('update:modelValue', false);
        // }
    };

    const validate = () => {

        if (paramCheck(param.schlDivCdDcd)) {
            showMessage('학교 구분을 선택하세요');
            return false;
        }
        if (paramCheck(param.schlInDivCdDcd)) {
            showMessage('재학 구분을 선택하세요');
            return false;
        }

        if(param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_CREDIT){
            if (paramCheck(param.regCrseCrdit)) {
                showMessage('수강학점을 입력하세요');
                return false;
            }

            if(param.regCrseCrdit > sprtCredit.value){
                showMessage('지원가능한 수강학점을 초과하였습니다.');
                param.regCrseCrdit = 0;
                return false;
            }

            if (paramCheck(param.degAcqsSchedPerid)) {
                showMessage('학위취득예정시기를 입력하세요');
                return false;
            }
        }
        if (paramCheck(school.value.scholNm)) {
            showMessage('학교를 선택하세요');
            return false;
        }
        if (paramCheck(major.value.majorNm)) {
            showMessage('전공을 선택하세요');
            return false;
        }
        if (paramCheck(param.majorNm2)) {
            showMessage('학과명을 입력하세요');
            return false;
        }

        if(param.schlDivCdDcd !== schlDivCdDcds.SCHL_DIV_CD_CREDIT) {
            if (paramCheck(param.sprtYear)) {
                showMessage('신청학기 년도를 선택하세요');
                return false;
            }
            if (paramCheck(param.termDivCdDcd)) {
                showMessage('신청학기를 선택하세요');
                return false;
            }
        }

        if (paramCheck(param.grdAvg)) {
            showMessage('신청학기 평점평균을 입력하세요');
            return false;
        }
        if (paramCheck(param.grdTot)) {
            showMessage('신청학기 평점총점을 입력하세요');
            return false;
        }

        if(transcriptFiles.value.files.length == 0){
            showMessage('성적증명서를 등록하세요');
            return false;
        }

        if (paramCheck(param.inetProofNo)) {
            showMessage('인터넷 증명서 발급번호를 입력하세요');
            return false;
        }

        if(reduce.etcBursAmt != '' && Number(reduce.etcBursAmt) > 0){
            reduce.etcBursAmtNm = "그외 장학금";
            // if (paramCheck(reduce.etcBursAmtNm)) {
            //     showMessage('기타 장학금명을 입력하세요');
            //     return false;
            // }
        }

        // if(reduce.etcBursAmtNm != ''){
        //     if (paramCheck(reduce.etcBursAmt)) {
        //         showMessage('기타 장학금을 입력하세요');
        //         return false;
        //     }
        // }

        if(param.sprtAmt > 0){
            if(tuitPayCertFiles.value.files.length == 0){
                showMessage('등록금 납입증명서를 등록하세요');
                return false;
            }

            if(paramCheck(param.termEndDd)){
                showMessage('학기 종료일(종강일)을 선택하세요');
                return false;
            }

            let toDay = dateToDateFormat(new Date,'yyyyMMdd');
            let endDt = param.termEndDd.replaceAll("-", "");
            // console.log("toDay : " + toDay);
            // console.log("endDt : " + endDt);
            if( endDt > toDay){
                showMessage('학기 종료후 신청이 가능합니다.');
                return false;
            }

            // console.log("endDt 6개월경과일 : " + dateToDateFormat(addDate(endDt, 186),'yyyyMMdd'));
            if(toDay > dateToDateFormat(addDate(endDt, 186),'yyyyMMdd')){
                showMessage('학기 종료일로부터 6개월 경과 시 신청이 불가합니다');
                return false;
            }
        }

        if(schlScholarCertFiles.value.files.length == 0){
            showMessage('장학금 (비)수혜 증명서를 등록하세요');
            return false;
        }
        return true;
    };

    const apply = async () => {
        if (reduce.etcBursAmt !== '' && reduce.etcBursAmt !== null) {
            reduce.etcBursAmt = Number(removeComma(reduce.etcBursAmt)); // 기타 입력장학금 숫자형변환
        }
        let payload = { ...param, majorCd: major.value.majorCd, majorNm: major.value.majorNm, schlCd: school.value.scholCd, schlNm: school.value.scholNm, ...fees, ...reduce,
            removedFiles: transcriptFiles.value.removed.concat(transcriptFiles.value.removed).concat(tuitPayCertFiles.value.removed).concat(schlScholarCertFiles.value.removed),
        };
        nullEmpty(payload);
        showLoading();
        let applyRes=null, transRes=null, tuitRes=null, schlScholarCertRes=null;

        param.dpstYmd = replaceDateToYmd(param.dpstYmd);

        if (param.costTuitFeeSn > 0) {
            applyRes = await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_SCHOOL}`, {costTuitFeeSn: param.costTuitFeeSn, params: {...payload}}, userAgreement, validate); }
        else {
            applyRes = await saveSupport(isLoading, `support/${ACT_INSERT_LRN_SPRT_SCHL}`, payload, userAgreement, validate);
            if (applyRes) {
                param.costTuitFeeSn = applyRes.costTuitFeeSn;
            }
        }

        if (param.costTuitFeeSn > 0) {
            if (checkUploadBinaries(transcriptFiles)) {
                transRes = await uploadCostproof({
                    params: {costTuitFeeSn: param.costTuitFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_SCHL_LAST_SEM_TRANS},
                    files: transcriptFiles.value.files
                });
            }
            if (checkUploadBinaries(tuitPayCertFiles)) {
                tuitRes = await uploadCostproof({
                    params: {costTuitFeeSn: param.costTuitFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_SCHL_TUIT_CERT},
                    files: tuitPayCertFiles.value.files
                });
            }
            if (checkUploadBinaries(schlScholarCertFiles)) {
                schlScholarCertRes = await uploadCostproof({
                    params: {costTuitFeeSn: param.costTuitFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_SCHL_SCHOLAR_CERT},
                    files: schlScholarCertFiles.value.files
                });
            }
        }

        // let failStr = getFileUploadFailResults({title: '성적증명서', res: transRes}, {title: '등록금 납입증명서', res: tuitRes});

        let failed = getFileUploadFailResults(transRes, tuitRes, schlScholarCertRes);

        // 비용신청테이블 인서트
        if (isSuccess(applyRes)) {
            // 신청완료
            showComplete(isLoading, applyRes, failed,()=>{
                // initLocalParams();
                closeModal();
            });
        }

        hideLoading();
    };


    const searchSchool = (type) => {
        if(type === 'college') {
            collegeModal.value = true;
        }
        if(type === 'major') {
            majorModal.value = true;
        }

        store.commit(`support/${MUT_HIDE_HEADER}`);
    }

    const cancel = () => {
        cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_SCHL, param.costTuitFeeSn,'학비 지원', () => {
            closeModal();
        });
    }

    const getBefTermBursAmt = () => {
        store.dispatch(`support/${ACT_GET_BEF_TERM_BURS_AMT}`).then(res => {
            if(lengthCheck(res)) {
                const items = getItem(res);
                param.befTermBursAmt = items.befTermBursAmt;
                param.bursAmtBnfitRegno = items.bursAmtBnfitRegno;
                befSchlDivCdDcd.value = items.schlDivCdDcd; // 직전학기 학기구분

                // 지원금액 적용
                if ( befSchlDivCdDcd.value == '2061001'){ // 방송통신대
                    appBefTermBursAmt.value =  items.befTermBursAmt;
                }else{
                    appBefTermBursAmt.value =  items.befTermBursAmt/2;
                }
                param.sprtAmt = -1*appBefTermBursAmt.value;
            }
        }).catch(e => {
            console.error(e);
        });
    }

    const calSupportFee = () => {
        totalPayment.value = fees.sprtEntrFee+fees.sprtTuitFee; // 지원대상금액 합계(입학금+수업료)
        totalReduce.value = reduce.redNtnBursAmt + reduce.etcBursAmt; // 감면금액 합계(국가장학금+그외 장학금)

        param.sprtExptAmt = totalPayment.value - reduce.etcBursAmt; // 총지원대상금액

        let payAmt = fees.sprtEntrFee+fees.sprtTuitFee-reduce.redGradeBursAmt-totalReduce.value; // 본인실납부금액(입학금+수업료)-(교내 성적장학금+그외 장학금)
        let tmpAmt = 0;

        //지원예상금액계산 min[지원대상금액의 50%, 본인실납부액]
        if (param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_KNOU) { // 방통대
            tmpAmt = param.sprtExptAmt;
        } else {
            tmpAmt = param.sprtExptAmt * 0.5;
        }

        if(payAmt >= tmpAmt){
            param.sprtAmt = tmpAmt;
        }else{
            param.sprtAmt = payAmt;
        }

    }

    const sprtCredit = ref(0);

    const getSprtCredit=()=>{
        store.dispatch(`support/${ACT_GET_LEARN_SUPPORT_SCHOOL_SPRT_CREDIT}`).then(res => {
            if( res.status && res.status.code == 200) {
                if (res.sprtCredit >= 140){
                    sprtCredit.value = 0;
                }else {
                    sprtCredit.value = 140-res.sprtCredit;
                }
            }

        }).catch(e => {
            console.error(e);
        });
    }

    const sprtCreditAvlChk=()=>{
        store.dispatch(`support/${ACT_GET_SCHL_CREDIT_APPLY_AVL_CHK}`).then(res => {
            if (res.status && res.status.code == 200) {
                if (res.creditApplyAvlYn != "Y") {
                    if (res.creditApplyAvlYn == "N1") {
                        showMessage('학점은행제 신청대상이 아닙니다.');
                        param.schlDivCdDcd = "";
                        return false;
                    } else if (res.creditApplyAvlYn == "N2") {
                        showMessage('학점은행제 신청대상이 아닙니다.');
                        param.schlDivCdDcd = "";
                        return false;
                    } else {
                        showMessage('학점은행제 신청대상이 아닙니다.');
                        param.schlDivCdDcd = "";
                        return false;
                    }
                }
            }

        }).catch(e => {
            console.error(e);
        });
    }

    watch(() => param.schlInDivCdDcd, () => {
        if( param.schlInDivCdDcd != '2062001'){
            fees.sprtEntrFee = 0;
        }
        calSupportFee();
    },{deep:true});

    watch(() => fees, () => {
        calSupportFee();
    },{deep:true});

    watch(() => reduce, () => {
        calSupportFee();
    },{deep:true});

    watch( () => param.schlDivCdDcd, async() => {
        console.log(param.schlDivCdDcd);
        // 학점은행제인 경우 수강학점과 학위취득예정시기 입력
        if(param.schlDivCdDcd === schlDivCdDcds.SCHL_DIV_CD_CREDIT) {
            await sprtCreditAvlChk(); // 학점은행제 신청가능 여부 체크
            await getSprtCredit(); //학점체크
            isAcademicCreditBank.value = true;
        } else {
            isAcademicCreditBank.value = false;
            param.regCrseCrdit = 0;
            param.degAcqsSchedPerid = '';
            sprtCredit.value = 0;
        }
        await calSupportFee ();
    });

    watch(() => [totalPayment.value, totalReduce.value], () => {
        calSupportFee ();
    });

    onMounted(() => {
        if (props.data != null) { // 수정mode
            setParams(param, props.data);

            setParams(fees, props.data);

            setParams(reduce, props.data);
            setParams(major.value, props.data);
            setCostProof(props.data.proofs, transcriptFiles, proofDivCdDcds.PROOF_DIV_CD_SCHL_LAST_SEM_TRANS);
            setCostProof(props.data.proofs, tuitPayCertFiles, proofDivCdDcds.PROOF_DIV_CD_SCHL_TUIT_CERT);
            setCostProof(props.data.proofs, schlScholarCertFiles, proofDivCdDcds.PROOF_DIV_CD_SCHL_SCHOLAR_CERT);

            userAgreement.value = true;

            school.value.scholCd = props.data.schlCd;
            school.value.scholNm = props.data.schlNm;

            if (props.data.befTermBursAmt !== 0 && props.data.befTermBursAmt !== '') {
                befSchlDivCdDcd.value = props.data.befSchlDivCdDcd;
                if (befSchlDivCdDcd.value == schlDivCdDcds.SCHL_DIV_CD_KNOU) { // 방송통신대
                    appBefTermBursAmt.value = props.data.befTermBursAmt;
                } else {
                    appBefTermBursAmt.value = props.data.befTermBursAmt / 2;
                }
                param.sprtAmt = -1 * appBefTermBursAmt.value;
            }
            param.dpstYmd = ymdStrToFormat(param.dpstYmd, '-');

        }else {
            getBefTermBursAmt(); // 직전학기 장학금 수혜금액정보 가져오기
        }
        // console.log('직전학기 금액 : '+param.befTermBursAmt+' / 직전학기 금액 관리번호 : '+param.bursAmtBnfitRegno);
    });

    return {
        schlInDivCdDcds, grdTots, grdTots2,
        schlDivCdDcdOpt, schlInDivCdDcdOpt, termDivCdDcdOpt,
        isReadOnly, param, major, school, fees, reduce, totalPayment, totalReduce, toggles,
        transcriptFiles, tuitPayCertFiles, majorModal, collegeModal, isAcademicCreditBank,
        showPrice, showReducedPrice, userAgreement, schlScholarCertFiles,
        apply, toggleGrdTot, selectGrdTot, selectGrdTot2,
        numbersOnly, emptyNumberOnClick, numberComma,
        closeToggles, closeModal, applyBtnEnable, cancel,
        searchSchool, calSupportFee, getBefTermBursAmt,
        appYear, isUseSubText, sprtCredit, getSprtCredit, extensions, usrAgrmntRef, sprtCreditAvlChk
    }
}