import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {
    dateDiff,
    initParams,
    isSuccess,
    nullEmpty,
    numberComma,
    paramCheck,
    removeComma,
    setParams
} from "@/assets/js/util";
import {closeToggles} from "@/assets/js/ui.init";
import {
    applyBtnEnable,
    cancelSupport,
    checkUploadBinaries, eduInstDivCds, getFileUploadFailResults,
    proofDivCdDcds,
    saveSupport, setCostProof, showComplete, sprtQlfcDivCds, sprtRsnDivCds,
    uploadCostproof,
    extensions
} from "@/assets/js/modules/support/support-common";
import {
    ACT_DELETE_CANCEL_LRN_SPRT_OFFCR,
    ACT_INSERT_LRN_SPRT_OFFCR,
    ACT_UPDATE_LEARN_SUPPORT_OFFICER  , MUT_SHOW_MAIN
} from "@/store/modules/support/support";
import {useStore} from "vuex";

export const SupportTuitionSubSetup = (props, {emit}) => {
    const {showMessage, showLoading, hideLoading} = useAlert();
    const isLoading = ref(false);
    const userAgreement = ref(false);
    const maxSupportAmount = 5000000;
    const isReadOnly = computed(() => props.data && props.data.stt === '00');
    const store = useStore();
    // const isMobile = computed(() => store.state.auth.isMobile);

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);

    const param = reactive({
        costOffcrSn: 0,
        offcrCrseNm: '', // 과정명
        eduInstDivCd: '', //'1',
        eduInstNm: '',
        crseBgngDd: '',
        crseEndDd: '',
        crseTuitFeePayDd: '',
        crsePayAmt: 0,
        sprtQlfcDivCd: '', //'1',
        sprtQlfcDivNm: '', //sprtQlfcDivCds[0].nm,
        sprtRsnDivCd: '', //'1',
        sprtRsnDivNm: '', //sprtRsnDivCds[0].nm,
        sprtRsn: '',
        sprtExptAmt: 0,
        sprtAmt: 0,
        rtnYn:'N',
        rtnAmt:0,
        addAmtYn:'N',
        addAmt:0,
        aprvrId: '',
        aprvrNm: '',
        aprvDt: '',
        awardYear:'',
        taxYn:''
    });

    const toggles = reactive({
        crseDtRange: false,
        crseTuitFeePayDd: false,
        crseBgngDd: false,
        crseEndDd: false,
    });

    const tuitPayCertFiles = ref({ files: [], removed: [], binaries: [] });

    const crseDateDiff = ref(0);
    const isConfirmation = ref(false);
    const confirmation = ref(false);
    const usrAgrmntRef = ref(null);

    const initLocalParams = () => {
        initParams(param);
        initParams(tuitPayCertFiles.value);
        userAgreement.value = false;
        if(props.data == null) {
            usrAgrmntRef.value.allUnChk();
        }
        closeToggles(toggles);
        isLoading.value = false;
        // closeModal();
    };

    const validate = () => {
        if (paramCheck(param.offcrCrseNm)) {
            showMessage('학습 구분을 선택해주세요.');
            return false;
        }
        if (paramCheck(param.eduInstDivCd)) {
            showMessage('교육기관 구분을 선택해주세요.');
            return false;
        }
        if (paramCheck(param.crseBgngDd) || paramCheck(param.crseEndDd)) {
            showMessage('교육기간을 선택해주세요.');
            return false;
        }
        if (paramCheck(param.crseTuitFeePayDd)) {
            showMessage('등록금 납입일을 선택해주세요.');
            return false;
        }
        if (paramCheck(param.crsePayAmt)) {
            showMessage('납입금액을 선택해주세요.');
            return false;
        }
        if (paramCheck(param.sprtQlfcDivCd)) {
            showMessage('지원자격 구분을 선택해주세요.');
            return false;
        }
        if((param.sprtQlfcDivCd == "3" || param.sprtQlfcDivCd == "4") && paramCheck(param.awardYear)){
            showMessage('수상년도를 입력해주세요.');
            return false;
        }else{
            param.awardYear = "";
        }
        if (paramCheck(param.sprtRsnDivCd)) {
            showMessage('지원사유를 선택해주세요.');
            return false;
        }
        if (paramCheck(param.sprtRsn)) {
            showMessage('지원사유를 입력해주세요.');
            return false;
        }
        if((param.eduInstDivCd === 1 || param.eduInstDivCd === '1') && crseDateDiff.value >= 180) {
            if(!confirmation.value) {
                showMessage('확약서동의를 해주세요.');
                return false;
            }
        }
        return true;
    };

    const apply = async () => {
        nullEmpty(param);
        showLoading();
        let applyRes = null, tuitUpRes = null;
        let hasTuitCertFile = checkUploadBinaries(tuitPayCertFiles);

        if (param.costOffcrSn > 0) {
            applyRes = await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_OFFICER}`,{costOffcrSn: param.costOffcrSn, params: {...param}}, userAgreement, validate);
        } else {
            applyRes = await saveSupport(isLoading, `support/${ACT_INSERT_LRN_SPRT_OFFCR}`, {...param}, userAgreement, validate);
            if (applyRes) {
                param.costOffcrSn = applyRes.costOffcrSn;
            }
        }

        if (param.costOffcrSn > 0) {
            if (hasTuitCertFile) {
                tuitUpRes = await uploadCostproof({
                    params: {costOffcrSn: param.costOffcrSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_OFFCR_RECEIPT},
                    files: tuitPayCertFiles.value.files,
                });
            }
        }

        // let failStr = getFileUploadFailResults({title: '등록금 납입증명서', res: tuitUpRes});

        let failed = getFileUploadFailResults(tuitUpRes);

        if (isSuccess(applyRes)) {
            showComplete(isLoading, applyRes, failed,()=>{
                closeModal();
                // initLocalParams();
            });
        }
        hideLoading();
    };

    const closeModal = () => {
        if (props.reList instanceof Function) props.reList();
        initLocalParams();

        // if(isMobile.value) {
        //     store.commit(`support/${MUT_SHOW_MAIN}`);
        // }else {
            store.commit(`support/${MUT_SHOW_MAIN}`);
            emit('update:modelValue',false);
        // }
    };

    const selectEduInst = (idx) => {
        param.eduInstDivCd = eduInstDivCds[idx].val;
        param.eduInstNm = '';//eduInstDivCds[idx].nm;

        if(param.eduInstDivCd=="1" && param.sprtRsnDivCd=="1"){ //영업업무관련
            param.taxYn="Y";
        }else{
            param.taxYn="N";
        }
    };

    const selectSprtQlfc = (idx) => {
        param.sprtQlfcDivCd = sprtQlfcDivCds[idx].val;
        param.sprtQlfcDivNm = sprtQlfcDivCds[idx].nm;
        param.awardYear = "";
    };

    const selectSprtRsn = (idx) => {
        param.sprtRsnDivCd = sprtRsnDivCds[idx].val;
        param.sprtRsnDivNm = sprtRsnDivCds[idx].nm;

        console.log("eduInstDivCd : "+param.eduInstDivCd);
        console.log("sprtRsnDivCd : "+param.sprtRsnDivCd);

        if(param.eduInstDivCd=="1" && param.sprtRsnDivCd=="1"){ //영업업무관련
            param.taxYn="Y";
        }else{
            param.taxYn="N";
        }
    };

    // watch(() => param.crsePayAmt, () => {
    //
    // });


    watch(() => [param.eduInstDivCd, param.crseBgngDd, param.crseEndDd], () => {
        crseDateDiff.value = dateDiff(param.crseBgngDd, param.crseEndDd);
        isConfirmation.value = (param.eduInstDivCd === 1 || param.eduInstDivCd === '1') && crseDateDiff.value >= 180;
    });

    watch(() => param.crsePayAmt, () => {
        let payAmount = param.crsePayAmt;
        if (payAmount && toString(payAmount).indexOf(',') > -1) {
            payAmount = removeComma(param.crsePayAmt);
        }
        if (payAmount > 0) {
            param.sprtExptAmt = (payAmount * 0.8 > maxSupportAmount ? maxSupportAmount : payAmount * 0.8 );
        }
    });

    onMounted(() => {
        if (props.data != null) {
            setParams(param, props.data);
            setCostProof(props.data.proofs, tuitPayCertFiles, proofDivCdDcds.PROOF_DIV_CD_OFFCR_RECEIPT);
            userAgreement.value = true;
        }
    });

    const cancel = () => {
        cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_OFFCR, param.costOffcrSn,'학비 지원', () => {
            closeModal();
        });
    }

    return {
        eduInstDivCds, sprtQlfcDivCds, sprtRsnDivCds,
        isReadOnly, param, userAgreement, toggles, tuitPayCertFiles,
        confirmation, isConfirmation,
        apply, closeModal, closeToggles,
        selectEduInst, selectSprtQlfc, selectSprtRsn, numberComma, applyBtnEnable, cancel, extensions, usrAgrmntRef
    }
}