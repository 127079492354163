<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{ 'is-active' : modelValue }">
    <!-- popup -->
    <div class="popup" id="popup-tuition-support-p057">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div class="popup-header">
          <h3 class="title">{{ title }}</h3>
        </div>
        <!-- popup-inner > popup-content -->
        <div class="popup-content">
          <div class="survey-container">
            <!-- popup-section:신청정보 -->
            <article class="popup-section section-form">
              <div class="section-content">
                <!-- kb-form-fields -->
                <div class="kb-form-fields">
                  <div class="kb-form-row">
<!--                    <div class="kb-form-column kb-form-column-title">-->
<!--                      <label class="kb-form-label"></label>-->
<!--                    </div>-->
                    <div class="kb-form-column column-whole kb-form-search">
                      <input type="text" class="kb-form-control column-whole" v-model="keyword" placeholder="검색어를 입력하세요" @keyup.enter="pagingFunc(1)" />
                      <button class="kb-form-search-btn" @click="pagingFunc(1)"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <!-- //kb-form-fields -->
              </div>
            </article>
            <article class="popup-section section-form">
              <div class="section-content">
                <!-- kb-form-fields -->
                <div class="kb-form-fields">
                  <div v-for="(item, idx) in items" class="kb-form-row" :key="idx">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <strong class="kb-form-label-text">{{ idx+1 }}</strong>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <span class="kb-form-text">{{ item[nm] }}</span>
                    </div>
                    <div class="kb-form-column column-last">
                      <button class="kb-btn-link" @click="handleSelect(idx)"><span class="text">선택</span></button>
                    </div>
                  </div>
                </div>
                <!-- //kb-form-fields -->
              </div>
            </article>
          </div>
        </div>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {getCollegeList, getMajorList} from '@/assets/js/modules/support/support-common';
import {reactive, ref, toRef} from 'vue';

export default {
  name: 'ListModal',
  components: {CommonPaginationFront},
  props: {
    modelValue: Boolean,
    selected: Object,
    listType: {
      type: String,
      required: true,
      default: 'college'
    }
  },
  emits: ['update:modelValue', 'update:selected'],
  setup(props, {emit}) {

    const type = toRef(props, 'listType');
    const items = ref([]);
    const keyword = ref('');
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });
    const title = ref(''), cd = ref(''), nm = ref('');

    const getList = () => {
      if (type.value === 'college') {
        title.value = '학교 검색';
        cd.value='scholCd';
        nm.value='scholNm';
        getCollegeList({keyword: keyword.value, ...paging}, items, paging);
      } else if (type.value === 'major') {
        title.value = '전공 검색';
        cd.value='majorCd';
        nm.value='majorNm';
        getMajorList({keyword: keyword.value, ...paging}, items, paging);
      }
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      // items.value = [];
      getList();
    }

    const closeModal = () => { emit('update:modelValue', false); }

    const handleSelect = (idx) => {
      emit('update:selected',items.value[idx]);
      closeModal();
    };

    // onMounted(() => {
    //   getList();
    // });

    return {
      keyword, cd, nm, title,
      items, paging,
      pagingFunc,
      handleSelect,
      closeModal,
    };
  }
};
</script>