<template>
  <div class="popup-container" :class="{'is-active' : modelValue }" @click="closeToggles(toggles)">
    <div class="popup" id="popup-tuition-support-p042">
      <div class="popup-inner">
        <form @submit.prevent>
          <div class="popup-header">
            <h3 class="title">부점장 최고경영자 과정 지원신청</h3>
          </div>
          <div class="popup-content">
            <div class="survey-container">
              <article class="popup-section section-form">
                <header class="section-header header-divider-v2">
                  <h4 class="title">신청정보</h4>
                </header>
                <div class="section-content">
                  <div class="kb-form-fields kb-form-fields-v2">
                    <Applicant />
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">교육기관 구분</strong>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div v-if="!isReadOnly" class="kb-form-check-group ps-0">
                          <div v-for="(item,idx) in eduInstDivCds" class="kb-form-check kb-form-check-inline" :key="idx">
                            <input type="radio" class="kb-form-check-input" name="chk_01" :id="`chk_01_${idx}`" v-model="param.eduInstDivCd" :value="item.val" @change="selectEduInst(idx)" />
                            <label :for="`chk_01_${idx}`" class="kb-form-check-label"><span class="text">{{ item.nm }}</span></label>
                          </div>
                        </div>
                        <div v-else>
                          <div class="kb-form-check kb-form-check-inline">
                            <input type="radio" class="kb-form-check-input" name="chk_01" :id="`chk_01`" v-model="param.eduInstDivCd" :disabled="isReadOnly" :value="param.eduInstDivCd" />
                            <label :for="`chk_01`" class="kb-form-check-label"><span class="text">{{ param.eduInstNm }}</span></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <RowInputText v-model="param.eduInstNm" title="교육기관명" placeholder="교육기관명을 입력하세요" :disabled="isReadOnly"/>
                    <RowInputText v-model="param.offcrCrseNm" title="과정명" placeholder="과정명을 입력하세요" :disabled="isReadOnly"/>
                    <DatepickerRange
                        v-model:bgng-value="param.crseBgngDd"
                        v-model:end-value="param.crseEndDd"
                        v-model:toggle="toggles.crseDtRange"
                        title="교육기간"
                        :disabled="isReadOnly"
                        @update:toggle="closeToggles(toggles, 'crseDtRange')"
                    />
                    <article v-if="isConfirmation" class="popup-section section-agree mt-5">
                      <header class="section-header">
                        <h4 class="title">확약서(6개월이상 과정)</h4>
                      </header>
                      <div class="section-content agree-content">
                        <div class="segment-box">
                          <p class="text">
                            본 과정 종료 시점부터 해당 교육기간을 초과하여 근무하지 아니하는 때에는 지급받은 금액을 반납할 것을 서약합니다. <br/>
                            다만, 의무근무기간의 일부만을 이행하였을 경우 이행하지 아니한 잔여 의무근무기간을 총 의무근무기간에 비례하여 반납합니다.
                          </p>
                        </div>
                      </div>
                      <div class="agree-check">
                        <div class="kb-form-check" @click="confirmation=!confirmation">
                          <input type="checkbox" v-model="confirmation" class="kb-form-check-input" id="chk_confirmation"/>
                          <label class="kb-form-check-label" for="chk_confirmation"><span class="text">동의완료</span></label>
                        </div>
                      </div>
                    </article>

                    <RowDatepicker
                        title="등록금 납입일"
                        v-model="param.crseTuitFeePayDd"
                        v-model:toggle="toggles.crseTuitFeePayDd"
                        :disabled="isReadOnly"
                        @update:toggle="closeToggles(toggles, 'crseTuitFeePayDd')"
                    />
                    <RowInputText v-model="param.crsePayAmt" :is-number="true" :is-money="true" title="납입금액" placeholder="납입금액을 입력하세요" :disabled="isReadOnly" />
                    <RowInputText v-model="param.sprtExptAmt" :is-number="true" :is-money="true" title="지급예상금액" :disabled="true">
                      <template v-slot:last>
                        <div class="kb-form-bottomtext">
                          납입금액의 80% 최대 500만원 지원 가능
                        </div>
                      </template>
                    </RowInputText>
                    <Upload
                        v-model="tuitPayCertFiles"
                        title="등록금 납입증명서"
                        placeholder="등록급 납입증명서를 등록하세요"
                        btn-title="등록금 납입증명서 등록"
                        sequence-key="proofDataSn"
                        :extensions="extensions"
                        :max-qty="10"
                        :is-payment="true"
                        :disabled="isReadOnly"
                        />
                    <div class="kb-form-row kb-form-receipt">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">지원자격 구분</strong>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div v-if="!isReadOnly" class="kb-form-check-block">
                          <div v-for="(item, idx) in sprtQlfcDivCds" class="kb-form-check" :key="idx">
                            <input v-model="param.sprtQlfcDivCd" type="radio" name="chk_02" :id="`chk_02_${idx}`" class="kb-form-check-input" :value="item.val" @change="selectSprtQlfc(idx)" />
                            <label :for="`chk_02_${idx}`" class="kb-form-check-label">{{ item.nm }}
                              <template v-if="(param.sprtQlfcDivCd == '3' || param.sprtQlfcDivCd == '4') && param.sprtQlfcDivCd==item.val">
                                <span>(수상년도:</span><input type="text" v-model="param.awardYear" class="kb-form-control" style="width: 90px;height: 25px;margin-top: -8px;margin-left: 7px;">년)
                              </template>
                            </label>

                          </div>
                        </div>
                        <div v-else class="kb-form-check-block">
                          <div class="kb-form-check">
                            <input v-model="param.sprtQlfcDivCd" type="radio" name="chk_02" :id="`chk_02`" class="kb-form-check-input" :disabled="isReadOnly"  />
                            <label :for="`chk_02`" class="kb-form-check-label">{{ param.sprtQlfcDivNm }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kb-form-row kb-form-receipt">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">지원사유</strong>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div v-if="!isReadOnly" class="kb-form-check-group ps-0">
                          <div v-for="(item,idx) in sprtRsnDivCds" class="kb-form-check" :key="idx">
                            <input v-model="param.sprtRsnDivCd" type="radio" name="chk_03" :id="`chk_03_${idx}`" class="kb-form-check-input"
                                   :value="item.val"
                                   @change="selectSprtRsn(idx)"/>
                            <label :for="`chk_03_${idx}`" class="kb-form-check-label">{{ item.nm }}</label>
                          </div>
                        </div>
                        <div v-else class="kb-form-check-group">
                          <div class="kb-form-check">
                            <input v-model="param.sprtRsnDivCd" type="radio" name="chk_03" :id="`chk_03`" class="kb-form-check-input" :disabled="isReadOnly"  />
                            <label :for="`chk_03`" class="kb-form-check-label">{{ param.sprtRsnDivNm }}</label>
                          </div>
                        </div>
                        <input v-model="param.awardYear" type="text" v-if="param.sprtRsnDivCd == '3' || param.sprtRsnDivCd == '4'">
                        <textarea v-model="param.sprtRsn" rows="5" class="kb-form-control" :disabled="isReadOnly"
                                  placeholder="지원사유를 입력해주세요." />
<!--                        <div class="textarea-bottom divider">-->
<!--                          <div class="bottom-column">-->
<!--                            <span class="counter">0 / 300</span>-->
<!--                          </div>-->
<!--                        </div>-->
                      </div>
                    </div>

                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">과세여부</strong>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div class="kb-form-check-group ps-0">
                          <div class="kb-form-check kb-form-check-inline">
                            <input type="radio" class="kb-form-check-input" v-model="param.taxYn" value="Y" :checked="param.taxYn == 'Y'" disabled />
                            <label class="kb-form-check-label"><span class="text">과세</span></label>
                          </div>
                          <div class="kb-form-check kb-form-check-inline">
                            <input type="radio" class="kb-form-check-input" v-model="param.taxYn" value="N" :checked="param.taxYn == 'N'" disabled />
                            <label class="kb-form-check-label"><span class="text">비과세</span></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article class="popup-section section-agree" v-if="param.rtnYn=='Y' || param.addAmtYn=='Y'">
                <header class="section-header header-divider-v2">
                  <h4 class="title">환입 및 추가지원금액</h4>
                </header>
                <div class="section-content">
                  <div class="kb-form-fields kb-form-fields-v2">
                    <RowInputText v-model="param.rtnAmt" title="환입금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.rtnYn=='Y'" />
                    <RowInputText v-model="param.addAmt" title="추가지원금액"  :is-number="true" :is-money="true" :disabled="true" v-if="param.addAmtYn=='Y'" />
                  </div>
                </div>
              </article>

              <article v-if="data == null" class="popup-section section-agree">
                <UserAgreement
                    ref="usrAgrmntRef"
                    v-model:user-agreement="userAgreement"
                    @applyBtnEnable="applyBtnEnable"
                />
<!--                <header class="section-header">-->
<!--                  <h4 class="title">증빙서류 제출시 유의사항</h4>-->
<!--                </header>-->
<!--                <div class="section-content agree-content">-->
<!--                  <div class="segment-box">-->
<!--                    <p class="text" data-index="1.">자기주도학습비는 직원의 자기계발 및 업무능력향상을 위해 은행재원으로 지원되고 있습니다. 본 제도의 취지에 맞지 않는 부당수령사례(환불/대리수강/증명서위변조/사은품 수령 등) 확인시 소속부점통보, 자기주도학습비 지원제한 및 각종 연수 참가 제한 조치되며 사안이 중대한 경우 인사조치 될 수 있습니다. <br/>상기 내용을 숙지 하였음을 확인합니다.</p>-->
<!--                    <p class="text">&nbsp;</p>-->
<!--                    <p class="text" data-index="2.">신청내용 검증을 위한 담당무서의 자료제출 및 개인정보 조회에 동의합니다.</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="agree-check">-->
<!--                  <div class="kb-form-check" @click="userAgreement=!userAgreement">-->
<!--                    <input type="checkbox" v-model="userAgreement" class="kb-form-check-input" id="chk_agree_01"/>-->
<!--                    <label class="kb-form-check-label" for="chk_agree_01"><span class="text">동의완료</span></label>-->
<!--                  </div>-->
<!--                </div>-->
              </article>
            </div>
          </div>
          <SupportPopupButton
              :stt="data && data.stt"
              :aply-stt-cd-dcd="data && data.aplySttCdDcd"
              :apply-btn-enable="applyBtnEnable"
              :user-agreement="userAgreement"
              :apply="apply"
              :cancel="cancel"
              :close-modal="closeModal"
          />
        </form>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>

    </div>
  </div>
</template>

<script>
import Applicant from '@/components/support/common/Applicant';
import RowInputText from '@/components/support/common/RowInputText';
import DatepickerRange from '@/components/support/common/DatepickerRange';
import RowDatepicker from '@/components/support/common/RowDatepicker';
import Upload from '@/components/support/common/Upload';
import {SupportTuitionSubSetup} from "@/assets/js/modules/support/support-tuition-sub-setup";
import SupportPopupButton from "@/components/support/common/SupportPopupButton";
import UserAgreement from "@/components/support/common/UserAgreement";

export default {
  name: 'SupportTuitionSubModal',
  components: {SupportPopupButton, Upload, RowDatepicker, DatepickerRange, RowInputText, Applicant, UserAgreement},
  props: {
    modelValue: Boolean,
    data: {type:Object, default: null},
    reList : Function
  },
  setup: SupportTuitionSubSetup
};
</script>